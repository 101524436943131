const selectors = {
    header: '.js--header',
    hamburger: '.js--menu-btn',
    panel: '.js--panel-menu',
    panelBtn: '.js--panel-btn',
};

class Header {
    constructor() {
        this.header = document.querySelector(selectors.header);
        this.hamburger = document.querySelector(selectors.hamburger);
        this.panel = document.querySelector(selectors.panel);
        this.panelBtn = document.querySelector(selectors.panelBtn);

        if (!this.header) {
            return;
        }

        this.toggleMenu();
        this.togglePanel();
    }

    toggleMenu(){
        this.hamburger.addEventListener('click', () => {
            this.header.classList.toggle('active');
            this.hamburger.classList.toggle('active');
        });
    }

    togglePanel() {
        if(!this.panelBtn) {
            return;
        }
        this.panelBtn.addEventListener('click',(e)=>{
            e.preventDefault();
            this.panel.classList.toggle('active');
        });
    }
}

export default Header;
