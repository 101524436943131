import { Swiper, Pagination, EffectCoverflow } from 'swiper';
import 'swiper/swiper.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/effect-coverflow/effect-coverflow.scss';
Swiper.use([Pagination, EffectCoverflow]);

class Slider {
    constructor() {
      this.init();
    }


    init(){
        const sliderMain = document.querySelector('.slider-main');

        if (!sliderMain) {
            return;
        }

        new Swiper('.swiper-container', {
            slidesPerView: 1,
            loop: true,
            centeredSlides: true,
            effect: 'coverflow',
            coverflowEffect: {
                rotate: 0,
                stretch: 0,
                depth: 90,
                modifier: 1,
                slideShadows : false,
            },
            pagination: {
                el: '.swiper-pagination',
                clickable: true,
            },
            breakpoints: {
                992: {
                    slidesPerView: 3,
                },
            },
        });
    }
}

export default Slider;

