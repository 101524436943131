/** HELPERS */
import './Helpers/Polyfill';
import animateScrollTo from 'animated-scroll-to';

/** STYLES */
import '../scss/Index.scss';

/** COMMON */
import Header from './Common/Header';
import Slider from './Common/Slider';
import ScrollLinks from './Common/ScrollLinks';
import HideOnOutside from './Common/hideOnOutside';
import GDPR from './Common/GDPR';
import Animations from './Common/Animations';

class IndexController {
    constructor (){
        new Header();
        new Slider();
        new ScrollLinks();
        new HideOnOutside();
        new GDPR();
        new Animations();
    }
}

// Loader babel polyfill only if needed
const BROWSER_UNSUPPORT_FEATURES = !window.Promise || !window.fetch || !window.Symbol || !Object.assign;

if (BROWSER_UNSUPPORT_FEATURES && typeof __jsVars !== 'undefined' && typeof __jsVars.dist !== 'undefined' && typeof __jsVars.dist.babelPolyfill !== 'undefined') {
    const js = document.createElement('script');
    js.src = __jsVars.dist.babelPolyfill;
    js.onload = function() {
        new IndexController();
    };
    js.onerror = function() {
        new IndexController();
        new Error('Failed to load script ' + __jsVars.dist.babelPolyfill);
    };
    document.head.appendChild(js);
} else {
    new IndexController();
}
