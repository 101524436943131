import animateScrollTo from "animated-scroll-to";

const selectors = {
    link: '.js--scroll-anchor',
}

class ScrollLinks {
    constructor() {
        this.scrollLinks = document.querySelectorAll(selectors.link);
        this.enableLinks();
        this.smoothScrollOnLoadHash();
    }

    enableLinks() {
        this.scrollLinks.forEach(link => {
            link.addEventListener('click', function (e) {
                const scrolledItem = document.querySelector(this.hash);

                if (!scrolledItem) {
                    return;
                }

                e.preventDefault()
                animateScrollTo(scrolledItem, {verticalOffset: -140});
            });
        });
    }

    smoothScrollOnLoadHash() {
        if (window.location.hash) {
            
            const hash = window.location.hash;
            const scrolledItem = document.querySelector(hash);

            if (!scrolledItem) {
                return;
            }
            animateScrollTo(scrolledItem, {verticalOffset: -140});
        }
    }
}

export default ScrollLinks;
