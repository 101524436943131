import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

class Animations {
    constructor(){
        this.hero();
        this.cta();
    }

    hero(){
        const section = document.querySelector('.hero');

        if (!section) {
            return;
        }

        ScrollTrigger.matchMedia({
            "(min-width: 992px)": function () {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".hero",
                        start: "0 15%",
                        scrub: true,
                        markers: false
                    }
                });
                tl.to(".hero__img--circle1", {y: 5, x: 10,  scale: 0.7}, 0)
                tl.to(".hero__img--triangles", {y: 70}, 0)
                tl.to(".hero__img--circle2", {y: 5, x: -60,  scale: 0.7}, 0)

            }, "(max-width: 991px)": function () {
                gsap.set('.hero__img--circle1, .hero__img--circle2', '.hero__img--triangles', {clearProps: "all"});
            }

        });
    }

    cta(){
        const section = document.querySelector('.location-main');

        if (!section) {
            return;
        }

        ScrollTrigger.matchMedia({
            "(min-width: 992px)": function () {
                const tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: ".location-main",
                        start: "0 45%",
                        scrub: true,
                        markers: false
                    }
                });
                tl.to(".location-main__img--circle1", {y: 5, x: -10,  scale: 0.8}, 0)
                tl.to(".location-main__img--triangles", {y: 100}, 0)
                tl.to(".location-main__img--circle2", {y: 5, x: -10,  scale: 0.8}, 0)

            }, "(max-width: 991px)": function () {
                gsap.set('.location-main__img--circle1, .location-main__img--circle2', '.location-main__img--triangles', {clearProps: "all"});
            }

        });
    }


}

export default Animations;
